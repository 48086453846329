import { Routes, Route, Link } from "react-router-dom";
import './App.css';
import LawRulexMenu from './LawRulexMenu';

import FalvWenshu from './FalvWenshu';
import LawRule from './LawRule';
import About from './About';
import Notfound from './Notfound';



function App() {
  return (
    <div class="左右布局">
      <LawRulexMenu />
      <div >
        <Routes>
          <Route path="/" element={<p>欢迎来到法律规则库，点击左侧导航栏的项目来选择您需要使用的服务。</p>} />
          <Route path="/FalvWenshu/*" element={<FalvWenshu />} />         
          <Route path="/LawRule/*" element={<LawRule />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
