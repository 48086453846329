import { useParams } from "react-router-dom";
import 民事起诉状模板 from './民事起诉状模板';
function WenshuMoban() {
    let params = useParams();
    let 书名 = params.name;
    let 模板 = <p>没有找到对应的模板。</p>;
    switch (书名) {
        case "民事起诉状":
            模板=<民事起诉状模板 />
            break;
        case "附带民事起诉状":
            break;
        default:
            模板 = <p>没有找到对应的模板。</p>;
    }
    return (
        <div>
            <h2>{书名}</h2>
            {模板}
        </div>
    );
}

export default WenshuMoban;