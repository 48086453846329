
function About() {
    return (
        <div >
            <h1 >关于</h1>
            <p >……</p>
        </div>
    );
}

export default About;