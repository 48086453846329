import './民事起诉状模板.css';
function 民事起诉状模板() {

    return (
        <div>
            <p>根据原告是自然人还是单位，分为两种形式：</p>
            <h3>1.原告是自然人的民事起诉状模板</h3>
            <div className='A4纸'>
                <p className='居中并加粗'>民事起诉状</p>
                <p className='正文段落'>原告：×××，男/女，××××年×月××日生，×族，…（写明工作单位和职务或职业），住……。联系方式：……。</p>
                <p className='正文段落'>法定代理人/指定代理人：×××，……。</p>
                <p className='正文段落'>委托诉讼代理人：×××，……。</p>
                <p className='正文段落'>被告：×××，……</p>
                <p className='正文段落'>……</p>
                <p className='正文段落'>（以上写明当事人和其他诉讼参加人的姓名或者名称等基本信息）</p>
                <p className='正文段落'>诉讼请求：</p>
                <p className='正文段落'>……</p>
                <p className='正文段落'>事实和理由：</p>
                <p className='正文段落'>……</p>
                <p className='正文段落'>证据和证据来源，证人姓名和住所：</p>
                <p className='正文段落'>……</p>
                <p className='正文段落'>此致</p>
                <p >x×××人民法院</p>
                <p className='正文段落'>附：本起诉状副本×份</p>
                <p className='签名等'>起诉人 (签名)X X X </p>
                <p className='签名等'>XXXX年XX月××日</p>
            </div>
            <p className='正文段落'>【说明】</p>
            <p className='正文段落'>1.本样式根据《中华人民共和国民事诉讼法》第一百二十条第一款、第一百二十一条制定，供公民提起民事诉讼用。</p>
            <p className='正文段落'>2.起诉应当向人民法院递交起诉状，并按照被告人数提出副本。</p>
            <p className='正文段落'>3.原告应当写明姓名、性别、出生日期、民族、职业、工作单位、住所、联系方式。原告是无民事行为能力或者限制民事行为能力人的，应当写明法定代理人姓名、性别、出生日期、民族、职业、工作单位、住所、联系方式，在诉讼地位后括注与原告的关系。</p>
            <p className='正文段落'>4.起诉时已经委托诉讼代理人的，应当写明委托诉讼代理人基本信息。</p>
            <p className='正文段落'>5.被告是自然人的，应当写明姓名、性别、工作单位、住所等信息；被告是法人或者其他组织的，应当写明名称、住所等信息。</p>
            <p className='正文段落'>6.原告在起诉状中直接列写第三人的，视为其申请人民法院追加该第三人参加诉讼。是否通知第三人参加诉讼，由人民法院审查决定。</p>
            <p className='正文段落'>7.起诉状应当由本人签名。</p>
            <a href="/法律文书/民间借贷纠纷案例/民事起诉状.docx">案例：民事起诉状.docx</a>
            <h3>2.原告是单位（即法人或其他组织）的民事起诉状模板</h3>
            <div className='A4纸'>
                <p className='居中并加粗'>民事起诉状</p>
                <p className='正文段落'>原告：×××，住所……。</p>
                <p className='正文段落'>法定代表人/主要负责人：×××，……（写明职务），联系方式：……。</p>
                <p className='正文段落'>委托诉讼代理人：×××，……。</p>
                <p className='正文段落'>被告：×××，……</p>
                <p className='正文段落'>……</p>
                <p className='正文段落'>（以上写明当事人和其他诉讼参加人的姓名或者名称等基本信息）</p>
                <p className='正文段落'>诉讼请求：</p>
                <p className='正文段落'>……</p>
                <p className='正文段落'>事实和理由：</p>
                <p className='正文段落'>……</p>
                <p className='正文段落'>证据和证据来源，证人姓名和住所：</p>
                <p className='正文段落'>……</p>
                <p className='正文段落'>此致</p>
                <p >x×××人民法院</p>
                <p className='正文段落'>附：本起诉状副本×份</p>
                <p className='签名等'>起诉人 (公章和签名)X X X </p>
                <p className='签名等'>XXXX年XX月××日</p>
            </div>    
            <p className='正文段落'>【说明】</p>
            <p className='正文段落'>1.本样式根据《中华人民共和国民事诉讼法》第一百二十条第一款、第一百二十一条制定，供法人或者其他组织提起民事诉讼用。</p>
            <p className='正文段落'>2.起诉应当向人民法院递交起诉状，并按照被告人数提出副本。</p>
            <p className='正文段落'>3.起诉时已经委托诉讼代理人的，应当写明委托诉讼代理人基本信息。</p>
            <p className='正文段落'>4.被告是自然人的，应当写明姓名、性别、工作单位、住所等信息；被告是法人或者其他组织的，应当写明名称、住所等信息。</p>
            <p className='正文段落'>5.原告在起诉状中直接列写第三人的，视为其申请人民法院追加该第三人参加诉讼。是否通知第三人参加诉讼，由人民法院审查决定。</p>
            <p className='正文段落'>6.起诉状应当加盖单位印章，并由法定代表人或者主要负责人签名。</p>
        </div>
    );
}

export default 民事起诉状模板;