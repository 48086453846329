import { Routes, Route, Link } from "react-router-dom";

import './LawRule.css';

function LawRule() {
    return (
        <div>
            <h1>法律规则</h1>
            <Routes>
                <Route index element={<p><a href="/法律规则/交通事故/交通事故赔偿计算规则.html">交通事故赔偿计算规则</a></p>} />              
                <Route path="Kaiting" element={<p>这是开庭规则库</p>} />
                <Route path="*" element={<p>没有找到规则网络。</p>} />
            </Routes>
        </div>
    );
}

export default LawRule;