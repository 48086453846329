
import { Routes, Route, Link, Outlet, useParams } from "react-router-dom";
import './FalvWenshu.css';
import WenshuMoban from './法律文书/WenshuMoban';

function FalvWenshu() {
    let params = useParams();
    return (
        <div>
            <div>
                <h2>常用法律文书</h2>
                <ul id="常用法律文书列表">
                    <li><Link to={`/FalvWenshu/民事起诉状`}>民事起诉状</Link></li>
                    <li><Link to={`/FalvWenshu/附带民事起诉状`}>附带民事起诉状</Link></li>
                </ul>
                <a href="/法律文书/民事起诉状.pdf">规范要求：民事起诉状.pdf</a>
            </div>
            <div></div>
            <Routes>
                <Route index element={<p>选择一个文书模板</p>} />
                <Route path=":name" element={<WenshuMoban />} />
                <Route path="test" element={<p>这是精确匹配：test</p>} />
                <Route path="*" element={<p>没有匹配的模板。</p>} />
            </Routes>

        </div>
    );
}

export default FalvWenshu;