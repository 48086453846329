import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import "./LawRulexMenu.css";
import 法书锤 from "./falv.png";
import 网址二维码 from "./网址二维码.png";

function LawRulexMenu() {
  return (
    <header className="顶部栏">
      <img src={法书锤} className="Logo" alt="logo" />
      <nav className="菜单列表" id="菜单">
        <NavLink activeClassName="active" to={'/FalvWenshu'} className="菜单项">法律文书</NavLink>
        <NavLink activeClassName="active" to={'/LawRule'} className="菜单项">法律规则</NavLink>
        <NavLink activeClassName="active" to={"/about"} className="菜单项">关于</NavLink>
      </nav>
      <span style={{ color: 'white' }}>v20220528</span>
      <img src={网址二维码} className="Logo" alt="网址二维码" />
    </header>
  );
}

export default LawRulexMenu;